
import React from "react"

import { connect } from 'react-redux';

import {basketActions, basketSelectors} from '../state/basket'

const data = [
  {
    id: 1,
    sku: 'PROD1',
    photo: '',
    title: 'Red box',
    description: 'Description of red box',
    price: 10.99,
  },
  {
    id: 2,
    sku: 'PROD2',
    photo: '',
    title: 'Green box',
    description: 'Description of green box',
    price: 20.99,
  },
  {
    id: 3,
    sku: 'PROD3',
    photo: '',
    title: 'Blue box',
    description: 'Description of blue box',
    price: 30.99,
  }
];

const itemStyle = {
  width: '200px',
  margin: '20px 20px',
  float: 'left'
}

const clearStyle = {
  clear: 'both'
}

const IndexPage = ({items, addItemToBasket, plusItemCount, minusItemCount, basket, total}) => (
  <>
    <h1>Basket simulations - List of products</h1>

    {data.map((element, index)=>(

      <div key={index} style={itemStyle}>
        <p>{element.title}</p>
        <p>{element.description}</p>
        <p>{element.price}</p>
        {items.hasOwnProperty(element.id) ?
          <>

            <p>Items {items[element.id]}</p>
          <button onClick={()=> plusItemCount(element.id)}>plus</button><br/>
          <button onClick={()=> minusItemCount(element.id)}>minus</button><br/>
          </>
          :
          <button onClick={()=> addItemToBasket(element)}>Add to basket</button>
        }
      </div>
    ))}

    <div style={clearStyle}/>

    <h1>Basket simulations - Basket (total)</h1>
    <p>Total items in basket: {total}</p>

    <h1>Basket simulations - Basket (items)</h1>

    {basket.map(({item, count}, index)=>(
      <div key={index} style={itemStyle}>
        <p>{item.title}</p>
        <p>{item.description}</p>
        <p>{item.price}</p>
        <p>{count}</p>
        <p>{item.price * count}</p>
      </div>
    ))}

  </>
)

const mapStateToProps = state => ({
  items: basketSelectors.getItemsIdAndCount(state),
  basket: basketSelectors.getItems(state),
  total: basketSelectors.getTotalItems(state)
});

const mapDispatchToProps = {
  addItemToBasket: basketActions.addItemToBasket,
  plusItemCount: basketActions.plusItemCount,
  minusItemCount: basketActions.minusItemCount,
  setItemCount: basketActions.setItemCount,
};
export default connect(mapStateToProps, mapDispatchToProps) (IndexPage);